var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-1 empty-component",style:({
    height: _vm.value.height + 'px',
  })},[_c('v-card',{staticClass:"empty-card pa-0 ma-0 elevation-6"},[_c('v-icon',{attrs:{"color":"primary","x-large":""},on:{"click":_vm.onOpenMenu}},[_vm._v(" mdi-plus ")]),_c('v-expand-transition',[(_vm.menu)?_c('div',{staticClass:"empty-container",attrs:{"absolute":"","background-color":'rgba(0,0,0,0.5)',"dark":"","shift":""}},[_c('v-icon',{staticClass:"close-icon",attrs:{"color":"white"},on:{"click":_vm.onCloseMenu}},[_vm._v(" mdi-close ")]),_c('v-row',{staticClass:"pa-0 ma-0 d-flex justify-center actions-wrapper"},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-slide-x-transition',{attrs:{"hide-on-leave":"","leave-absolute":""}},[_c('div',{key:_vm.activeAppName,staticClass:"action-description"},[_c('h2',[_vm._v(_vm._s(_vm.activeAppName))]),_c('span',[_vm._v(" "+_vm._s(_vm.activeApp ? _vm.activeApp.display.description : "-"))])])]),_c('HelpFormInputVue',{attrs:{"dark":"","label":'Search for Views',"helpText":'Use this field to find target view'},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(!_vm.selectedApp)?_c('v-row',{staticClass:"pa-0 ma-0 d-flex justify-center"},_vm._l((_vm.filterApps()),function(app,i){return _c('v-col',{key:i,staticClass:"d-flex justify-center",attrs:{"cols":"3"}},[_c('AppIcon',{attrs:{"active":_vm.activeApp.name === app.display.name,"value":app},on:{"mouseover":() => {
                      _vm.activeApp = app;
                      _vm.activeAppName = app.display.name;
                    },"click":function($event){return _vm.onAppSelected(app)}}})],1)}),1):_vm._e(),(_vm.search && !_vm.selectedApp && _vm.filterApps().length)?_c('v-divider',{staticClass:"mt-2 mb-2",attrs:{"color":"white"}}):_vm._e(),(_vm.selectedApp || _vm.search)?_c('v-row',{staticClass:"pa-0 ma-0 d-flex justify-center"},[_vm._l((_vm.filterViews()),function(view,i){return _c('v-col',{key:i,staticClass:"d-flex justify-center",attrs:{"cols":"3"}},[_c('AppIcon',{attrs:{"active":_vm.activeApp.display.name === view.display.name,"value":view},on:{"mouseover":() => {
                      _vm.activeApp = view;
                      _vm.activeAppName = view.display.name;
                    },"click":function($event){return _vm.onViewSelected(view)}}})],1)}),(_vm.selectedApp)?_c('div',{staticClass:"back-to-apps",on:{"click":_vm.backToApps}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-arrow-left-bottom ")]),_vm._v(" Change App ")],1):_vm._e()],2):_vm._e()],1)],1)],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }