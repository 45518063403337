<template>
  <div
    class="pa-1 empty-component"
    :style="{
      height: value.height + 'px',
    }"
  >
    <v-card class="empty-card pa-0 ma-0 elevation-6">
      <v-icon @click="onOpenMenu" color="primary" x-large> mdi-plus </v-icon>
      <v-expand-transition>
        <div
          v-if="menu"
          absolute
          class="empty-container"
          :background-color="'rgba(0,0,0,0.5)'"
          dark
          shift
        >
          <v-icon @click="onCloseMenu" color="white" class="close-icon">
            mdi-close
          </v-icon>
          <v-row class="pa-0 ma-0 d-flex justify-center actions-wrapper">
            <v-col cols="10">
              <v-slide-x-transition hide-on-leave leave-absolute>
                <div :key="activeAppName" class="action-description">
                  <h2>{{ activeAppName }}</h2>
                  <span>
                    {{ activeApp ? activeApp.display.description : "-" }}</span
                  >
                </div>
              </v-slide-x-transition>

              <HelpFormInputVue
                dark
                v-model="search"
                :label="'Search for Views'"
                :helpText="'Use this field to find target view'"
              ></HelpFormInputVue>
              <!-- <v-divider color="white" class="mt-2 mb-2"></v-divider> -->

              <v-row
                v-if="!selectedApp"
                class="pa-0 ma-0 d-flex justify-center"
              >
                <v-col
                  cols="3"
                  class="d-flex justify-center"
                  v-for="(app, i) in filterApps()"
                  :key="i"
                >
                  <AppIcon
                    @mouseover="
                      () => {
                        activeApp = app;
                        activeAppName = app.display.name;
                      }
                    "
                    @click="onAppSelected(app)"
                    :active="activeApp.name === app.display.name"
                    :value="app"
                  >
                  </AppIcon>
                </v-col>
              </v-row>
              <v-divider
                v-if="search && !selectedApp && filterApps().length"
                color="white"
                class="mt-2 mb-2"
              ></v-divider>

              <v-row
                v-if="selectedApp || search"
                class="pa-0 ma-0 d-flex justify-center"
              >
                <v-col
                  cols="3"
                  class="d-flex justify-center"
                  v-for="(view, i) in filterViews()"
                  :key="i"
                >
                  <AppIcon
                    @mouseover="
                      () => {
                        activeApp = view;
                        activeAppName = view.display.name;
                      }
                    "
                    @click="onViewSelected(view)"
                    :active="activeApp.display.name === view.display.name"
                    :value="view"
                  >
                  </AppIcon>
                </v-col>
                <div
                  v-if="selectedApp"
                  class="back-to-apps"
                  @click="backToApps"
                >
                  <v-icon color="white"> mdi-arrow-left-bottom </v-icon>
                  Change App
                </div>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>


<script>
import HelpFormInputVue from "../../../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import { GUI } from "../../GrapUI.config.js";
import AppIcon from "./AppIcon.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AppIcon,
    HelpFormInputVue,
  },
  data() {
    return {
      menu: false,
      apps: GUI.apps,
      views: GUI.views,
      activeAppName: "",
      activeApp: {
        display: {},
      },
      selectedApp: undefined,
      search: "",
    };
  },
  methods: {
    onOpenMenu() {
      this.$emit("onExpand");
      setTimeout(() => {
        this.menu = true;
      }, 350);
    },
    onCloseMenu() {
      this.$emit("onCollapse");
      this.menu = false;
    },
    onAppSelected(app) {
      console.log("app: ", app);
      this.selectedApp = app;
    },
    onViewSelected(view) {
      console.log("view: ", view);

      this.menu = false;
      this.selectedApp = undefined;

      this.$emit("onAdd", this.value, view);
    },
    backToApps() {
      this.selectedApp = undefined;
    },
    filterApps() {
      if (!this.search) return this.apps;

      const searchRegexp = new RegExp(`.*${this.search}.*`, "ig");
      const filtered = this.apps.filter(
        (app) =>
          String(app.name).match(searchRegexp) ||
          String(app.description).match(searchRegexp)
      );

      // if (filtered.length) {
      //   this.activeApp = filtered[0];
      //   this.activeAppName = filtered[0].name;
      // }

      return filtered;
    },
    filterViews() {
      if (!this.search && !this.selectedApp) return [];

      if (!this.search && this.selectedApp) return this.selectedApp.views;

      const searchRegexp = new RegExp(`.*${this.search}.*`, "ig");
      let filtered = [];

      if (this.search && !this.selectedApp) {
        filtered = this.views.filter(
          (view) =>
            String(view.display.name).match(searchRegexp) ||
            String(view.display.description).match(searchRegexp)
        );
      }

      if (this.search && this.selectedApp) {
        filtered = this.selectedApp.views.filter(
          (view) =>
            String(view.display.name).match(searchRegexp) ||
            String(view.display.description).match(searchRegexp)
        );
      }

      // if (filtered.length) {
      //   this.activeApp = filtered[0];
      //   this.activeAppName = filtered[0].name;
      // }

      return filtered.slice(0, 6);
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-component{
  // transition: none!important;
  // transition: all 0.4s;
  // -webkit-transition: all 0.4s;
}

.empty-card {
  height: 100%;
  width: 100%;
  overflow: hidden;
  // background: rgb(79,79,79,0.3);
  background: transparent;
  backdrop-filter: blur(20px);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0.1) 63%
  );
  justify-content: center;
  display: flex;

  &::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    background: radial-gradient(
      circle,
      rgba(205, 48, 59, 0.2) 0%,
      rgba(205, 48, 59, 0.3) 50%,
      rgba(205, 48, 59, 0.3) 63%
    );
    opacity: 0;
    transition: all 0.2s;
  }

  transition: all 0.2s;

  border: 1px solid #3c4454;
  &:hover {
    &::after {
      content: "";
      opacity: 0.2;
    }
  }

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
  .empty-container {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    background: rgba(14, 23, 33, 0.8);
    backdrop-filter: blur(1px);
    z-index: 9;

    .actions-wrapper {
      position: relative;
      .action-description {
        color: white;
        position: absolute;
        top: -50px;
      }
    }
  }

  .back-to-apps {
    position: absolute;
    bottom: -24px;
    color: white;
    cursor: pointer;
  }

  .empty-data {
    color: white;
  }
}
</style>